import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconWater.module.css';

const IconWater = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="26"
      height="16"
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 5.5C4.85375 5.5 7.18125 1.75 7.18125 1.75C7.18125 1.75 9.50875 5.5 12.6125 5.5C15.715 5.5 18.8188 1.75 18.8188 1.75C18.8188 1.75 21.9225 5.5 24.25 5.5"
        stroke="#6E7191"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 14.25C4.85375 14.25 7.18125 10.5 7.18125 10.5C7.18125 10.5 9.50875 14.25 12.6125 14.25C15.715 14.25 18.8188 10.5 18.8188 10.5C18.8188 10.5 21.9225 14.25 24.25 14.25"
        stroke="#6E7191"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconWater.defaultProps = {
  className: null,
  rootClassName: null,
};

IconWater.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconWater;
