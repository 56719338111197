import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconClock.module.css';

const IconClock = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 16 16"><rect x="0" y="0" width="24" height="16" fill="none" stroke="none" />
      <path d="M8.00033 0.666504C3.95016 0.666504 0.666992 3.94984 0.666992 7.99984C0.666992 12.05 3.95016 15.3332 8.00033 15.3332C12.0505 15.3332 15.3337 12.05 15.3337 7.99984C15.3337 3.94984 12.0505 0.666504 8.00033 0.666504ZM8.00033 13.9998C4.68666 13.9998 2.00033 11.3137 2.00033 7.99984C2.00033 4.68617 4.68666 1.99984 8.00033 1.99984C11.314 1.99984 14.0003 4.68617 14.0003 7.99984C14.0003 11.3137 11.314 13.9998 8.00033 13.9998ZM10.4717 9.5285C10.732 9.78884 10.732 10.211 10.4717 10.4713C10.3417 10.6013 10.1708 10.6665 10.0003 10.6665C9.82982 10.6667 9.65916 10.6015 9.52899 10.4713L7.52899 8.47134C7.40399 8.34634 7.33366 8.17684 7.33366 7.99984V3.99984C7.33366 3.63167 7.63216 3.33317 8.00033 3.33317C8.36849 3.33317 8.66699 3.63167 8.66699 3.99984V7.72384L10.4717 9.5285Z" fill="#6E7191" />
    </svg>
  );
};

const { string } = PropTypes;

IconClock.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClock.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClock;
