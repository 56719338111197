import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconAccessibility.module.css';

const IconAccessibility = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.811 16.4999C12.145 19.0879 9.796 20.9999 7 20.9999C3.6865 20.9999 1 18.3134 1 14.9999C1 12.5784 2.4345 10.4919 4.5 9.54395"
        stroke="#6E7191"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 5L8 14L15.5 13.5L17 19H18.5"
        stroke="#6E7191"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 3C9 3.53043 8.78929 4.03914 8.41421 4.41421C8.03914 4.78929 7.53043 5 7 5C6.46957 5 5.96086 4.78929 5.58579 4.41421C5.21071 4.03914 5 3.53043 5 3C5 2.46957 5.21071 1.96086 5.58579 1.58579C5.96086 1.21071 6.46957 1 7 1C7.53043 1 8.03914 1.21071 8.41421 1.58579C8.78929 1.96086 9 2.46957 9 3V3Z"
        stroke="#6E7191"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 9H14.5"
        stroke="#6E7191"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconAccessibility.defaultProps = {
  className: null,
  rootClassName: null,
};

IconAccessibility.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAccessibility;
