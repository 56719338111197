import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconNature.module.css';

const IconNature = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M11.9998 16.7998L5.5998 10.3998M11.9998 23.9998V11.1998V23.9998ZM11.9998 11.9998V16.7998V11.9998ZM11.9998 11.9998C11.9998 10.3024 11.3255 8.67455 10.1253 7.47432C8.92506 6.27409 7.29719 5.5998 5.5998 5.5998H0.799805V10.3998C0.799805 12.0972 1.47409 13.7251 2.67432 14.9253C3.87455 16.1255 5.50242 16.7998 7.1998 16.7998H11.9998V11.9998ZM11.9998 11.9998H16.7998C18.4972 11.9998 20.1251 11.3255 21.3253 10.1253C22.5255 8.92506 23.1998 7.29719 23.1998 5.5998V0.799805H18.3998C16.7024 0.799805 15.0746 1.47409 13.8743 2.67432C12.6741 3.87455 11.9998 5.50242 11.9998 7.1998V11.9998ZM11.9998 11.9998L18.3998 5.5998L11.9998 11.9998Z"
          stroke="#6E7191"
          strokeWidth="1.6"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const { string } = PropTypes;

IconNature.defaultProps = {
  className: null,
  rootClassName: null,
};

IconNature.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconNature;
