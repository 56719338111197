import React from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';

import css from './IconEmptyStar.module.css';

const IconEmptyStar = props => {
  const { rootClassName, className } = props;
  const classes = clsx(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 16 16"
    >
      <g fill="#ffdf00">
        <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256l4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73l3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356l-.83 4.73zm4.905-2.767l-3.686 1.894l.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575l-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957l-3.686-1.894a.503.503 0 0 0-.461 0z" />
      </g>
    </svg>
  );
};

IconEmptyStar.defaultProps = {
  rootClassName: null,
  className: null,
};

IconEmptyStar.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconEmptyStar;
