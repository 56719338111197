import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconRoad.module.css';

const IconRoad = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.440104 23.2031C0.877604 23.7344 1.40885 24 2.03385 24H21.3464C22.0339 24 22.5651 23.7344 22.9401 23.2031C23.3776 22.6406 23.4714 22.0469 23.2214 21.4219L16.8464 1.40625C16.5026 0.46875 15.862 0 14.9245 0H8.36198C7.95573 0 7.5651 0.132812 7.1901 0.398438C6.8151 0.664062 6.5651 1 6.4401 1.40625L0.0651042 21.4219C-0.0911458 22.0469 0.0338542 22.6406 0.440104 23.2031ZM8.36198 2.01562H10.6589V5.01562C10.6589 5.67188 10.987 6 11.6432 6C12.2995 6 12.6276 5.67188 12.6276 5.01562V2.01562H14.9245L21.3464 21.9844H13.6589V18C13.6589 17.3438 13.3151 17.0156 12.6276 17.0156H10.6589C9.97135 17.0156 9.6276 17.3438 9.6276 18V21.9844H2.03385L8.36198 2.01562ZM10.8464 14.0156H12.4401C12.7526 14.0156 13.0104 13.8906 13.2135 13.6406C13.4167 13.3906 13.487 13.1094 13.4245 12.7969L12.7214 8.8125C12.6276 8.28125 12.2995 8.01562 11.737 8.01562H11.4557C10.9557 8.01562 10.612 8.28125 10.4245 8.8125L9.72135 12.7969C9.72135 13.1094 9.83073 13.3906 10.0495 13.6406C10.2682 13.8906 10.5339 14.0156 10.8464 14.0156Z"
        fill="#6E7191"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconRoad.defaultProps = {
  className: null,
  rootClassName: null,
};

IconRoad.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconRoad;
