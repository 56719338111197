import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconSeasonal.module.css';

const IconSeasonal = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3 4.09985H4.70002C1.9386 4.09985 1.5 4.63843 1.5 7.39985V22.9999C1.5 25.7613 2.2386 26.4999 5.00002 26.4999H23.3C26.0614 26.4999 26.5 25.7613 26.5 22.9999V7.39985C26.5 4.63843 26.0614 4.09985 23.3 4.09985Z"
        stroke="#6E7191"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 1.5V6.5"
        stroke="#6E7191"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 1.5V6.5"
        stroke="#6E7191"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8.7998H26"
        stroke="#6E7191"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0439 15.1869L14.9986 11.97C14.6843 11.0026 13.3157 11.0026 13.0014 11.97L11.9561 15.1869H8.57368C7.55652 15.1869 7.1336 16.4885 7.9565 17.0863L10.693 19.0745L9.64774 22.2914C9.33342 23.2588 10.4406 24.0632 11.2635 23.4654L14 21.4772L16.7365 23.4654C17.5594 24.0632 18.6666 23.2588 18.3523 22.2914L17.307 19.0745L20.0435 17.0863C20.8664 16.4885 20.4435 15.1869 19.4263 15.1869H16.0439Z"
        stroke="#6E7191"
        strokeWidth="1.3"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconSeasonal.defaultProps = {
  className: null,
  rootClassName: null,
};

IconSeasonal.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconSeasonal;
