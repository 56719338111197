import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconGastronomy.module.css';

const IconGastronomy = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.9146 3.13672V8.24779" stroke="#6E7191" strokeWidth="1.8" strokeLinecap="round" />
      <path d="M26.0342 3.10352V8.50518" stroke="#6E7191" strokeWidth="1.8" strokeLinecap="round" />
      <path
        d="M29.3273 3C29.3273 2.50294 28.9244 2.1 28.4273 2.1C27.9303 2.1 27.5273 2.50294 27.5273 3H29.3273ZM25.5898 12.0427V11.1427H24.6898V12.0427H25.5898ZM24.6898 26.487C24.6898 26.984 25.0928 27.387 25.5898 27.387C26.0869 27.387 26.4898 26.984 26.4898 26.487H24.6898ZM27.5273 3C27.5273 4.33166 27.5444 5.80212 27.5211 7.12081C27.4973 8.46782 27.4313 9.53299 27.2965 10.086L29.0453 10.5121C29.2355 9.73186 29.2976 8.46371 29.3208 7.15262C29.3445 5.81322 29.3273 4.30079 29.3273 3H27.5273ZM27.2965 10.086C27.2241 10.3833 26.9714 10.6505 26.5393 10.864C26.3341 10.9654 26.1194 11.0379 25.9326 11.0842C25.8401 11.1071 25.7593 11.1225 25.6953 11.1318C25.6634 11.1364 25.6377 11.1393 25.6183 11.1409C25.5982 11.1426 25.5891 11.1427 25.5898 11.1427V12.9427C25.9838 12.9427 26.6834 12.8005 27.3367 12.4777C27.9943 12.1528 28.7929 11.5481 29.0453 10.5121L27.2965 10.086ZM24.6898 12.0427V26.487H26.4898V12.0427H24.6898Z"
        fill="#6E7191"
      />
      <path
        d="M6.33367 26.134C5.85579 26.2707 5.57925 26.769 5.716 27.2469C5.85274 27.7247 6.35099 28.0013 6.82887 27.8645L6.33367 26.134ZM12.1368 25.4095L12.3844 26.2748C12.7706 26.1643 13.0368 25.8112 13.0368 25.4095H12.1368ZM12.1368 16.6404H13.0368V15.7404H12.1368V16.6404ZM6.00001 11.2045L6.90001 11.2045L6.00001 11.2045ZM6.00008 3.25586V2.35586H5.10008V3.25586L6.00008 3.25586ZM21.4017 3.25586H22.3017V2.35586H21.4017V3.25586ZM21.4017 11.2045L22.3017 11.2045L21.4017 11.2045ZM15.282 16.6404V15.7404H14.382V16.6404H15.282ZM15.282 25.4095H14.382V26.0804L15.0249 26.272L15.282 25.4095ZM20.3582 27.8617C20.8345 28.0037 21.3358 27.7327 21.4778 27.2563C21.6197 26.78 21.3487 26.2787 20.8724 26.1368L20.3582 27.8617ZM6.82887 27.8645L12.3844 26.2748L11.8892 24.5443L6.33367 26.134L6.82887 27.8645ZM13.0368 25.4095V16.6404H11.2368V25.4095H13.0368ZM12.1368 15.7404C11.93 15.7404 11.4777 15.6719 10.885 15.4803C10.308 15.2938 9.65526 15.0087 9.04717 14.6195C7.82005 13.8341 6.90003 12.7181 6.90001 11.2045L5.10001 11.2045C5.10005 13.5712 6.57317 15.1732 8.0768 16.1356C8.83408 16.6203 9.6311 16.9667 10.3313 17.193C11.0159 17.4144 11.6683 17.5404 12.1368 17.5404V15.7404ZM6.90001 11.2045C6.89998 9.41848 6.90008 6.89693 6.90008 3.25586L5.10008 3.25586C5.10008 6.89678 5.09998 9.41853 5.10001 11.2045L6.90001 11.2045ZM6.00008 4.15586H21.4017V2.35586H6.00008V4.15586ZM20.5017 3.25586C20.5017 6.36695 20.5017 9.08765 20.5017 11.2045L22.3017 11.2045C22.3017 9.08765 22.3017 6.36694 22.3017 3.25586H20.5017ZM20.5017 11.2045C20.5017 12.668 19.5392 13.7879 18.2537 14.5965C17.6233 14.993 16.9571 15.2851 16.3899 15.4769C15.7949 15.678 15.3938 15.7404 15.282 15.7404V17.5404C15.6916 17.5404 16.316 17.4019 16.9663 17.1821C17.6443 16.9529 18.4428 16.604 19.212 16.1201C20.7256 15.1681 22.3017 13.57 22.3017 11.2045L20.5017 11.2045ZM14.382 16.6404V25.4095H16.182V16.6404H14.382ZM15.0249 26.272L20.3582 27.8617L20.8724 26.1368L15.5391 24.547L15.0249 26.272Z"
        fill="#6E7191"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconGastronomy.defaultProps = {
  className: null,
  rootClassName: null,
};

IconGastronomy.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconGastronomy;
