import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconBus.module.css';

const IconBus = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg 
      className={classes}
      xmlns="http://www.w3.org/2000/svg" 
      width="18" height="18" 
      preserveAspectRatio="xMidYMid meet" 
      viewBox="0 0 24 24">
        <rect x="0" y="0" width="24" height="24" fill="none" stroke="none" />
        <g fill="none" stroke="#4e4b66" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M19 17h2l.64-2.54a6 6 0 0 0 0-2.92l-1.07-4.27A3 3 0 0 0 17.66 5H4a2 2 0 0 0-2 2v10h2m10 0h-4"/>
          <circle cx="6.5" cy="17.5" r="2.5"/>
          <circle cx="16.5" cy="17.5" r="2.5"/>
        </g>
    </svg>
  );
};

const { string } = PropTypes;

IconBus.defaultProps = {
  className: null,
  rootClassName: null,
};

IconBus.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconBus;
