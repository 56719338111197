import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconMuseum.module.css';

const IconMuseum = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 9C15.7956 9 16.5587 8.68393 17.1213 8.12132C17.6839 7.55871 18 6.79565 18 6C18 5.20435 17.6839 4.44129 17.1213 3.87868C16.5587 3.31607 15.7956 3 15 3C14.2044 3 13.4413 3.31607 12.8787 3.87868C12.3161 4.44129 12 5.20435 12 6C12 6.79565 12.3161 7.55871 12.8787 8.12132C13.4413 8.68393 14.2044 9 15 9Z"
        stroke="#6E7191"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0514 19.25L8.92849 12.4567C9.02358 12.4193 9.11921 12.3812 9.21598 12.3425C10.4859 11.8357 11.9536 11.25 15 11.25C18.1836 11.25 20.0222 11.903 21.0914 12.3931L18.9486 19.25L11.0514 19.25Z"
        stroke="#6E7191"
        strokeWidth="1.5"
      />
      <path d="M10.8 25.2V21.8H19.2V25.2H10.8Z" stroke="#6E7191" strokeWidth="1.6" />
      <path d="M12.75 21.25V20.75H17.25V21.25H12.75Z" stroke="#6E7191" strokeWidth="1.5" />
    </svg>
  );
};

const { string } = PropTypes;

IconMuseum.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMuseum.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMuseum;
