import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconFreeCancellation.module.css';

const IconFreeCancellation = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg 
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="18" 
      height="18" 
      preserveAspectRatio="xMidYMid meet" 
      viewBox="0 0 24 24"><rect x="0" y="0" width="24" height="24" fill="none" stroke="none" />
      <path d="M11.21 20H5V10h14v4.38l2-2V6c0-1.1-.9-2-2-2h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h8.21l-2-2zM5 6h14v2H5V6zm11.54 16.5L13 18.96l1.41-1.41l2.12 2.12l4.24-4.24l1.41 1.41l-5.64 5.66zM10.41 14L12 15.59L10.59 17L9 15.41L7.41 17L6 15.59L7.59 14L6 12.41L7.41 11L9 12.59L10.59 11L12 12.41L10.41 14z" fill="#4e4b66"/>
    </svg>
  );
};

const { string } = PropTypes;

IconFreeCancellation.defaultProps = {
  className: null,
  rootClassName: null,
};

IconFreeCancellation.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconFreeCancellation;
