import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import css from './IconHiking.module.css';

const IconHiking = props => {
  const { className, rootClassName } = props;
  const classes = clsx(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 12.5L18.75 14.375L13.75 10.625L12.5 17.5L16.875 21.25L17.5 26.875"
        stroke="#6E7191"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 10.625V26.875"
        stroke="#6E7191"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 21.25L10 26.875"
        stroke="#6E7191"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 10.625C8.75 11.875 7.5 15 7.5 15L10 16.25"
        stroke="#6E7191"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 8.125C15.663 8.125 16.2989 7.86161 16.7678 7.39277C17.2366 6.92393 17.5 6.28804 17.5 5.625C17.5 4.96196 17.2366 4.32607 16.7678 3.85723C16.2989 3.38839 15.663 3.125 15 3.125C14.337 3.125 13.7011 3.38839 13.2322 3.85723C12.7634 4.32607 12.5 4.96196 12.5 5.625C12.5 6.28804 12.7634 6.92393 13.2322 7.39277C13.7011 7.86161 14.337 8.125 15 8.125Z"
        stroke="#6E7191"
        strokeWidth="1.875"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconHiking.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHiking.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHiking;
